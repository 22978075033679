#language-selector {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

#language-selector-item {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.8);
    box-shadow: 0 0 8px 5px rgba(255, 255, 255, 0.15);
    width: 50px;
    height: 50px;
    margin: 10px;
}

#language-selector-item:hover {
    background-color: rgba(255, 255, 255, 0.15);
    cursor: pointer;
}

.language-item {
    font-family: 'Comfortaa', Arial;
    font-size: 1.25em;
}

.language-item-active {
    font-size: 1.5em;
    font-weight: bold;
}