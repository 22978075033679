* {
  margin: 0;
  padding: 0;
  color: white;
}

body {
  background-color: black;
}

canvas {
  position: fixed;
  z-index: -20;
}

#fading-background {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: black;
  z-index: -10;
}

.fading-background-animation {
  animation: fading-background 0.75s ease-out 0s 1 forwards;
}

@keyframes fading-background {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

#container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

.container-side {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.container-side-item {
  margin: 0 2vh;
}

#logo {
  margin: 12vh 0;
  width: 45vh;
  max-width: 60%;
}

#content-container {
  width: 75%;
  max-width: 400px;
  padding: 40px;
  margin-top: 2vh;
  margin-bottom: 2vh;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.8);
  box-shadow: 0 0 8px 5px rgba(255, 255, 255, 0.15);
}

.text-large {
  font-family: 'Comfortaa', Arial;
  font-size: 1.5em;
  font-weight: bold;
}

.text-small {
  font-family: 'Comfortaa', Arial;
  font-size: 1em;
  color: darkgray;
}